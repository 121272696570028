import React from 'react'

import BlockContent from '../block-content'
import NonExpansiblePanel from '../../mui-components/NonExpansiblePanel/NonExpansiblePanel'

const FactPageContent = props => {
  const title = props.title
  const content = props._rawContent ? <BlockContent blocks={props._rawContent} /> : null

  return (
    <NonExpansiblePanel title={title} highlight>
      {content}
    </NonExpansiblePanel>
  )
}

export default FactPageContent
