import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Fact from '../components/page-inner/fact'
import Layout from '../components/layout'

export const query = graphql`
  query FactTemplateQuery($id: String!) {
    fact: sanityQuickFacts(id: { eq: $id }) {
      id
      title
      metaTitle
      metaDescription
      orderNumber
      _rawContent
    }
    relatedFacts: allSanityQuickFacts(sort: { fields: orderNumber }) {
      nodes {
        id
        orderNumber
        path
      }
    }
  }
`

const FactTemplate = props => {
  const { data, errors } = props
  const factData = data && data.fact
  const facts = data && data.relatedFacts

  const title = 'Quick facts'
  const metaTitle = factData.metaTitle || factData.title
  const metaDescription = factData.metaDescription

  return (
    <Layout title={title} {...props}>
      {errors && <SEO title="GraphQL Error" />}
      {factData && <SEO title={metaTitle} description={metaDescription} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {factData && <Fact {...factData} facts={facts} />}
    </Layout>
  )
}

export default FactTemplate
